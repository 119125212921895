import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import Auth from '@aws-amplify/auth';
import {ToastrService} from "ngx-toastr";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor( private _router: Router, private _toastrService: ToastrService) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      return Auth.currentAuthenticatedUser().then(() => { return true; })
      .catch(() => {
        this._toastrService.warning("Vous devez vous connecter ou créer un compte pour accéder à cette page ", "Connectez-vous !");
        this._router.navigate(['sign-in']);
        return false;
      });
  }
}

export class CVSubsection {
  title: string;
  subtitle?: string;
  subsubtitle?: string;
  text: string;
  chips: string[];

    constructor(title, subtitle, subsubtitle, text) {
    this.title = title;
    this.subtitle = subtitle;
    this.subsubtitle = subsubtitle;
    this.text = text;
    }
}

import { Injectable } from '@angular/core';

import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private server = 'https://4xayco9563.execute-api.eu-west-1.amazonaws.com/';
  private apiUrl = 'production/';
  private gatewayUrl: string;
  constructor(private http: HttpClient) {

    this.gatewayUrl = this.server + this.apiUrl;
  }

  public postMail(message: string): any {
      const apiPath = this.gatewayUrl + 'email';
      const mailContent = { 'message': message };

      return this.http.post<any>(apiPath, mailContent).subscribe(resp => {console.log(resp);});
  }

}


@Injectable()
export class CustomInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): any {
        if(req.url.indexOf('4xayco9563.execute-api.eu-west-1.amazonaws') !== -1)
        {
          const authReq = req.clone({
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'x-api-key': '2RtMu1bu2H25jxB1eX7sc4hMS3Pz3x5J7VPmC0p6'
            })
          });
          return next.handle(authReq);
        }
        return next.handle(req);
    }
}

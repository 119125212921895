import {WalletUtils} from "./wallet-utils";

export class Transaction {
  currency: string;
  quantity: number;
  purchaseDate?: Date;
  purchaseFiatCost: number;

  constructor(currency: string, quantity: number, purchaseDate: Date, purchaseFiatCost: number){
    this.currency = currency;
    this.quantity = WalletUtils.roundDecimal(quantity,8);
    this.purchaseDate = purchaseDate;
    this.purchaseFiatCost = WalletUtils.roundDecimal(purchaseFiatCost,2);
  }
}

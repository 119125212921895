import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  tiles = [
    {text: 'Accéder à mon CV en ligne, bientôt aussi disponible en PDF.', action: 'Mon CV', cols: 1, rows: 1, link: '/cv', disable: 'false'},
    {text: 'Transformez vos CSV en fichier XML pour vos demandes de chômage partiel', action: 'CoviXML', cols: 1, rows: 1, link: '/covixml', disable: 'false'},
    {text: 'Connectez-vous et accéder à vos fonds stockés dans le fond BTR Fund', action: 'Crypto', cols: 1, rows: 1, link: '/crypto', disable: 'false'},
    {text: 'Bientôt disponible', action: 'Formation AWS', cols: 1, rows: 1, link: '/', disable: 'true'},
    {text: 'Bientôt disponible', action: 'Quittances de loyer', cols: 1, rows: 1, link: '/', disable: 'true'},
  ];
  items = [
  { title: 'Auto-entrepreneur', dates: '2015 - 2016', texte: 'Création d\'une entreprise micro BIC pour permettre le développement d\'une plateforme d\'e-learning pour l\'enseignement supérieur' },
  { title: 'SNCF Réseau', dates: '2015 - 2016', texte: 'Développement de solutions embarquées pour des robots luttant contre la désertification dans le désert du Tchad. Travaux réalisés en collaboration avec SpirOps et un doctorant.' },
  { title: 'Sopra Steria', dates: '2016 - 2020', texte: 'Architecte de solutions pour Sopra Steria, j\'interviens sur des missions de refonte d\'applications, mise en place de nouveaux outils ainsi que la refonte d\'un SI client (Monolithe vers services modulaires). ' },
  { title: 'Viamedis', dates: '2020 - 2021', texte: 'Architecte chez Viamedis afin d\'accompagner la transformation digitale du groupe dans les aspects d\'industrialisation et processus notamment.' },
  { title: 'Cloudreach', dates: '2021 - Aujourd\'hui', texte: 'Consultant comme Cloud System Developer Senior \& Cloud Architect pour nos clients souhaitant migrer ou héberger de nouveaux workload sur le Cloud AWS ' }];
  gridListColumns: number;

  constructor() { }

  ngOnInit() {
      this.gridListColumns = (window.innerWidth <= 1160) ? (window.innerWidth <= 500) ? 1 : 2 : 3;
  }

    onResize(event) {
      this.gridListColumns = (event.target.innerWidth <= 1160) ? (event.target.innerWidth <= 500) ? 1 : 2 : 3;
    }

}

import {Injectable} from '@angular/core';
import Auth, {CognitoHostedUIIdentityProvider} from '@aws-amplify/auth';
import {Hub, ICredentials} from '@aws-amplify/core';
import {Subject, Observable} from 'rxjs';
import {CognitoUser} from 'amazon-cognito-identity-js';

export interface NewUser {
  email: string,
  nickname: string,
  password: string
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public loggedIn: boolean;
  private _authState: Subject<CognitoUser | any> = new Subject<CognitoUser | any>();

  public static GOOGLE = CognitoHostedUIIdentityProvider.Google;

  public cognitoUser: CognitoUser;
  public nickname: string;
  public email: string;

  constructor() {
    Hub.listen('auth', (data) => {
      const {channel, payload} = data;
      if (channel === 'auth') {
        this._authState.next(payload.event);
      }
    });
    Auth.currentAuthenticatedUser()
      .then(user => {
        this.nickname = user.attributes.nickname;
        this.email = user.attributes.email;
        this.loggedIn = true;
      })
      .catch(() => this.loggedIn = false);
  }

  signUp(user: NewUser): Promise<CognitoUser | any> {
    return Auth.signUp({
      "username": user.nickname,
      "password": user.password,
      "attributes": {
        "email": user.email,
        "nickname": user.nickname
      }
    });
  }

  signIn(username: string, password: string): Promise<CognitoUser | any> {
    return new Promise((resolve, reject) => {
      Auth.signIn(username, password)
        .then((user: CognitoUser | any) => {
          if (user.challengeName == 'NEW_PASSWORD_REQUIRED') {
            this.nickname = user.challengeParam.userAttributes.nickname;
            this.email = user.challengeParam.userAttributes.email;
          } else {
            this.nickname = user.attributes.nickname;
            this.email = user.attributes.email;
            this.loggedIn = true;
          }
          resolve(user);
          this.cognitoUser = user;
        }).catch((error: any) => reject(error));
    });
  }

  signOut(): Promise<any> {
    return Auth.signOut()
      .then(() => this.loggedIn = false)
  }

  socialSignIn(provider: CognitoHostedUIIdentityProvider): Promise<ICredentials> {
    return Auth.federatedSignIn({
      'provider': provider
    });
  }

  forceChangePassword(password: string): Promise<any> {
    let userAttrs = {
        nickname: this.cognitoUser.getUsername()
    };
    if (this.cognitoUser) {
      return Auth.completeNewPassword(this.cognitoUser, password, userAttrs).then(r => console.log(r));
    }
  }

  getCurrentSession() {
    return Auth.currentSession();
  }
}

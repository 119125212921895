import { Component, OnInit } from '@angular/core';
import { CVSection } from './cv-section';
import { CVSubsection } from './cv-subsection';
import { trigger, style, transition, animate, keyframes, query, stagger,state } from '@angular/animations';

@Component({
  selector: 'app-cv-section',
  templateUrl: './cv-section.component.html',
  styleUrls: ['./cv-section.component.scss'],
  animations: [
    trigger('animations', [
      state("leave", style({ display: 'none' })),
      state("enter", style({ display: 'block' })),
      transition('* => enter', [
        query(':self', style({ display: 'block' })),
        query(':self', stagger('300ms', [
          animate('1s ease-in',
            keyframes([
              style({ opacity: 0, transform: 'translate(-75px)', offset: 0}),
              style({ opacity: .5, transform: 'translate(35)', offset: 0.3}),
              style({ opacity: 1, transform: 'translate(0)', offset: 1})
            ])
          )
        ]), {optional: true }),
      ]),
      transition('* => leave', [
        query(':self', stagger('300ms', [
          animate('1s ease-in',
            keyframes([
              style({ opacity: 1, transform: 'translate(0)', offset: 0}),
              style({ opacity: .5, transform: 'translate(35)', offset: 0.3}),
              style({ opacity: 0, transform: 'translate(+75px)', offset: 1})
            ])
          )
        ]), {optional: true }),
      ])
    ])
  ]
})
export class CvSectionComponent implements OnInit {

  sections: CVSection[];


  constructor() {


    let quisuisje = new CVSubsection("","","","Passioné par l\'informatique, je recherche des challenges\
                                                                                                   professionnels à relever : Accompagner mes clients dans la\
                                                                                                   refonte de leurs SI, dans l\'intégration d’une brique primordiale\
                                                                                                   ou encore dans un build from scatch d\'une nouvelle solution.\
                                                                                                   Ces défis s’accompagnent de l\'apprentissage de la culture\
                                                                                                   d’entreprise client : comprendre son métier, pérenniser son SI\
                                                                                                   et assurer la réussite d’un projet de son cadrage jusqu’à son\
                                                                                                   exploitabilité.");


    let experience1 = new CVSubsection("Sopra Steria Group | Ingénieur d\'étude","Avril 2016 - Aujourd\'hui","Septembre 2019 – Aujourd\'hui | Architecte","Refonte d\'un monolithe vers du mini service, animation des\
                                                                                                                                                            ateliers d\'architecture, mise en place et intégration de la\
                                                                                                                                                            solution dans le SI client. Déroulement du projet en mode Agile\
                                                                                                                                                            avec suivi de 2 équipes Agile chacune composée de 7 personnes.");
    experience1.chips = ["Angular","Postgres","Elastic Search","Kafka","openjdk","nginx"];
    let experience2 = new CVSubsection("","","Mai 2018 – Septembre 2019 | Architecte","Mise en place d\'une solution d\'API Management visant à exposer\
                                                                                        les API de la sphère ministérielle au travers d\'un canal\
                                                                                        mutualisé. Cadrage, mise en place de la solution et accompagnement\
                                                                                        de la migration des API existantes. Encadrement d\'une équipe de 6\
                                                                                        personnes.");
    experience2.chips = ["Axway", "Apache", "PHP","Cassandra"];
    let experience3 = new CVSubsection("","","Avril 2016 – Mai 2018 | Ingénieur d’étude","Alternant, développeur puis référent technique sur le build d\'une\
                                                                                           solution visant à dématérialiser la facturation de l\'état. Projet\
                                                                                           de grande envergure réalisé par une équipe de 100 personnes.\
                                                                                           Intégré aux équipes, je suis devenu référent transverse technique\
                                                                                           et intégration continue du projet.");
    experience3.chips = ["Java", "Maven","Apache","Jenkins", "SonarQube", "CAST","Artifactory","Oracle Database"];
    let experience4 = new CVSubsection("Bruno Trinta | Autoentrepreneur","Juillet 2015 - Juillet 2016","","Prestations dans le développement de solution informatique.\
                                                                                                            Développement d\'un prototype de plateforme d\'e-learning pour\
                                                                                                            l\'enseignement supérieur, développement de programme\
                                                                                                            algorithmique appliqué au robot Nao et participation à\
                                                                                                            l\'élaboration de logiciel.");

    let experience5 = new CVSubsection("SNCF Réseaux | Développeur","Juin 2015 - Septembre 2016","","Développement d\'analyse vidéo pour systèmes embarqués.\
                                                                                                      Programmation d\'un robot intelligent dans une équipe intégrée\
                                                                                                      (doctorant + entreprise SpirOps, intelligence artificielle) pour\
                                                                                                      lutter contre la désertification dans le désert du Tchad et\
                                                                                                      trouver des cas d\'application près des voieries SNCF.");
    experience5.chips = ["C#", "OpenCV"];

    let formation1 = new CVSubsection("BADGE - Architecte Solution", "TELECOM Sud Paris", "2019","");
    let formation2 = new CVSubsection("Diplôme ingénieur en informatique", "Institut des Sciences et Techniques des Yvelines", "2017", "");
    let formation3 = new CVSubsection("Bacaclauréat S (spécialisation Mathématiques)", "Lycée Georges Brassens", "2012", "");

    let certification1 = new CVSubsection("AWS Certified Solutions Architect - Associate","Amazon Web Services (AWS)","Feb. 2020 - Feb. 2023","");
    let certification2 = new CVSubsection("DevOps Foundation Certification","DevOps Institute","2018","");

    let publications = new CVSubsection("","","","");

    let quisuisjeArray = [ quisuisje ];
    let experiencesArray = [ experience1, experience2, experience3, experience4, experience5 ];
    let formationsArray = [ formation1, formation2 ];
    let certificationsArray = [ certification1, certification2 ];

    this.sections = [
      { title: 'Qui suis-je ?', hidden: false, subsections: quisuisjeArray, maticon: 'person' },
      { title: 'Mes expériences', hidden: false, subsections: experiencesArray, maticon: 'work'},
      { title: 'Mes formations', hidden: false, subsections: formationsArray, maticon: 'school' },
      { title: 'Mes certifications', hidden: false, subsections: certificationsArray, maticon: 'collections_bookmark'},
      { title: 'Mes publications', hidden: false, subsections: null, maticon: 'book' }
    ];

  }

  ngOnInit() {
  }

  hideSection(element, index,section: CVSection){
    var booleanValue = (element.value === 'true');
    this.sections[index].hidden = !booleanValue;
    element.value = (!booleanValue).toString();
  }

  exitSection(index, section:CVSection){
    this.sections[index].hidden = !this.sections[index].hidden;
  }

  isMinimumWidth(){

  }

}

import {Component, OnInit, ViewChild} from '@angular/core';
import {CryptoApiService} from '../../../services/crypto-api/crypto-api.service';
import {AuthService} from "../../../auth/auth.service";
import {WalletComponent} from "../wallet/wallet.component";

@Component({
  selector: 'app-crypto',
  templateUrl: './crypto.component.html',
  styleUrls: ['./crypto.component.scss']
})
export class CryptoComponent implements OnInit {
  unknownCryptoUser: boolean;
  nickname: string;

  @ViewChild(WalletComponent, {read: WalletComponent, static: false})
  private walletComponent: WalletComponent;

  constructor(
    private _cryptoApiService: CryptoApiService,
    private _authService: AuthService) {
  }

  ngOnInit() {
    this._authService.getCurrentSession().then(user => {
      this._cryptoApiService.setCurrentIdToken(user['idToken'].getJwtToken());
      console.log(user['idToken'].getJwtToken());
      this._cryptoApiService.getMe().subscribe(resp => {
        if (resp.status == 200) {
          this.nickname = resp.body[0].username;
        }
      });
    });

  }

}

import {Component, OnInit} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../auth.service";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: "profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"]
})
export class ProfileComponent implements OnInit {
  hide = true;
  errorMessage = '';
  profileForm: FormGroup = new FormGroup({
    email: new FormControl({value: '', disabled: true}, [Validators.email, Validators.required]),
    nickname: new FormControl({value: '', disabled: true}, [Validators.min(10)])
  });
  forcePasswordForm: FormGroup = new FormGroup({
    password: new FormControl('', [ Validators.required, Validators.pattern("^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$")]),
    confirmPassword: new FormControl('', [Validators.required, Validators.pattern("^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$")])
  });

  get emailInput() {
    return this.profileForm.get("email");
  }

  get nicknameInput() {
    return this.profileForm.get("nickname");
  }

  get forcePasswordInput(){
    return this.forcePasswordForm.get("password");
  }

  get forceConfirmPasswordInput(){
    return this.forcePasswordForm.get("confirmPassword");
  }

  constructor(
    public _authService: AuthService,
    private _router: Router,
    private _toastrService: ToastrService
  ) {
  }

  ngOnInit() {
    this.getUserInfo();
  }

  shouldEnableSubmit() {
    return (
      !this.forcePasswordInput.valid ||
      !this.forceConfirmPasswordInput.valid ||
      this.forcePasswordInput.value != this.forceConfirmPasswordInput.value
    );
  }

  signOut() {
    this._authService.signOut()
      .then(() => this._router.navigate(['/']))
  }

  private async getUserInfo() {
    this.emailInput.setValue(this._authService.email);
    this.nicknameInput.setValue(this._authService.nickname);
  }

  forceChangePassword() {
    this._authService.forceChangePassword(this.forcePasswordInput.value).then(r => {
      this._toastrService.success("Votre mot de passe a été mis à jour, veuillez vous reconnecter ", "Félicitations !");
      this._router.navigate(['/sign-in'])
    });
  }
}

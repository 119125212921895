import {WalletUtils} from "./wallet-utils";

export class WalletContent {
  currency: string;
  quantity: number;
  fiatCost: number;
  currentValue: number;

  constructor(currency: string, quantity: number, fiatCost: number){
    this.currency = currency;
    this.quantity = WalletUtils.roundDecimal(quantity,8);
    this.fiatCost = WalletUtils.roundDecimal(fiatCost,2);
    this.currentValue = 0;
  }

  setCurrentValue(currentValue: number) {
    this.currentValue = WalletUtils.roundDecimal(currentValue,2);
  }
}

import {Component, OnInit} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../auth.service";
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';

@Component({
  selector: "app-sign-up",
  templateUrl: "./sign-up.component.html",
  styleUrls: ["./sign-up.component.scss"]
})
export class SignUpComponent implements OnInit {
  hide = true;
  errorMessage = '';
  signupForm: FormGroup = new FormGroup({
    email: new FormControl("", [Validators.email, Validators.required]),
    password: new FormControl("", [Validators.required]),
    nickname: new FormControl("", [Validators.min(10)])
  });

  get emailInput() {
    return this.signupForm.get("email");
  }

  get passwordInput() {
    return this.signupForm.get("password");
  }

  get nicknameInput() {
    return this.signupForm.get("nickname");
  }

  constructor(
    private _authService: AuthService,
    private _router: Router,
    private _toastr: ToastrService
  ) {
  }

  ngOnInit() {
  }

  getEmailInputError() {
    if (this.emailInput.hasError("email")) {
      return "Please enter a valid email address.";
    }
    if (this.emailInput.hasError("required")) {
      return "An Email is required.";
    }
  }

  getPasswordInputError() {
    if (this.passwordInput.hasError("required")) {
      return "A password is required.";
    }
  }

  shouldEnableSubmit() {
    return (
      !this.emailInput.valid ||
      !this.passwordInput.valid ||
      !this.nicknameInput.valid
    );
  }

  signUp() {
    this._authService
      .signUp({
        email: this.emailInput.value,
        password: this.passwordInput.value,
        nickname: this.nicknameInput.value
      })
      .then(data => {
        this._router.navigate(["/"]);
      })
      .catch((error: any) => {
        this.errorMessage = error.message;
        switch (error.code) {
          case "InvalidParameterException":
            this.errorMessage = 'Vous devez rentrer une adresse mail valide et un mot de passe d\'au moins 8 caractères contenant une majuscule, une minuscule et un chiffre';
            break;
          case "UsernameExistsException":
            this.errorMessage = 'Un utilisateur existe déjà avec ce username ou cette adresse mail';
            break;
        }
      })
  }
}

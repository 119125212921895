import {Component, OnInit} from '@angular/core';
import {CryptoApiService} from "../../../services/crypto-api/crypto-api.service";
import {AuthService} from "../../../auth/auth.service";
import {Transaction} from "./transaction";
import {Sort} from "@angular/material/sort";
import {WalletContent} from "./wallet-content";
import {forkJoin} from "rxjs";
import {WalletUtils} from "./wallet-utils";

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.scss']
})
export class WalletComponent implements OnInit {

  transactions: Transaction[];
  walletContent: WalletContent[];
  btcvalue: any;
  crovalue: any;
  panelOpenState: boolean;

  constructor(
    private _cryptoApiService: CryptoApiService,
    private _authService: AuthService) {
  }

  ngOnInit() {
    this.transactions = [];
    this.walletContent = [];
    this.getWallet();
  }

  getWallet() {
    console.log(this._cryptoApiService.getCurrentIdToken());
    console.log(this.walletContent.length);
    if (this._cryptoApiService.getCurrentIdToken() != null && !(this.walletContent.length > 0)) {

      //Get summary wallet
      forkJoin([this._cryptoApiService.getMyWallet(), this._cryptoApiService.getActualBitcoinValue()]).subscribe(resp => {
        if (resp[0].status == 200) {
          resp[0].body.forEach(element => this.walletContent.push(new WalletContent(element.currency, element.token_qty, element.fiat_cost)));
        }
        if (resp[1].status == 200) {
          //this.btcvalue = resp[1].body.EUR.last;
          this.btcvalue = resp[1].body.data.BTC.EUR.PRICE.replace(/[^0-9.,]+/,'');
          this.crovalue = resp[1].body.data.CRO.EUR.PRICE.replace(/[^0-9.,]+/,'');
          this.walletContent.forEach(element => {
            let currentCurrency: number;
            switch(element.currency){
              case "BTC":
                currentCurrency = this.btcvalue;
                break;
              case "CRO":
                currentCurrency = this.crovalue;
                break;
              default:
                currentCurrency = 0;
                break;
            }
            element.setCurrentValue(WalletUtils.roundDecimal(element.quantity * currentCurrency,2));
          });
        }
      });
    }
  }

  getTransactions(){
    if (this._cryptoApiService.getCurrentIdToken() != null && !(this.transactions.length > 0)) {
      //Get transactions
      this._cryptoApiService.getMyTransactions().subscribe(resp => {
        if (resp.status == 200) {
          resp.body.forEach(element => this.transactions.push(new Transaction(element.currency_name, element.token_qty, element.purchase_date, element.fiat_cost)));
        }
      });
    }
  }

  sortData(sort: Sort) {
    const data = this.transactions.slice();
    if (!sort.active || sort.direction === '') {
      this.transactions = data;
      return;
    }

    this.transactions = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'monnaie':
          return compare(a.currency, b.currency, isAsc);
        case 'quantite':
          return compare(a.quantity, b.quantity, isAsc);
        case 'prixAchat':
          return compare(a.purchaseFiatCost, b.purchaseFiatCost, isAsc);
        case 'dateAchat':
          return compare(a.purchaseDate, b.purchaseDate, isAsc);
        default:
          return 0;
      }
    });
  }

  sortDataSummary(sort: Sort) {
    const data = this.walletContent.slice();
    if (!sort.active || sort.direction === '') {
      this.walletContent = data;
      return;
    }

    this.walletContent = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'sum_currency':
          return compare(a.currency, b.currency, isAsc);
        case 'sum_quantity':
          return compare(a.quantity, b.quantity, isAsc);
        case 'sum_cost':
          return compare(a.fiatCost, b.fiatCost, isAsc);
        case 'sum_currentValue':
          return compare(a.currentValue, b.currentValue, isAsc);
        default:
          return 0;
      }
    });
  }

}

function compare(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}



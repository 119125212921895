import { Component, Input, OnInit } from '@angular/core';
import { trigger, transition, animate, style, keyframes } from '@angular/animations';
import { query, state, stagger } from '@angular/animations';

@Component({
  selector: 'carousel',
  exportAs: 'carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  animations:[
    trigger('fade', [
      transition('void => *', [style({ opacity: 0 }), animate('300ms', style({ opacity: 1 }))]),
      transition('* => void', [style({ opacity: 1 }), animate('200ms', style({ opacity: 0 }))]),
    ]),
    trigger('fade3', [
      transition('* => *', [style({ opacity: 0 }), animate('300ms', style({ opacity: 1 }))]),
    ]),
    trigger('fade2', [
      transition('* => *', [
        query(':self', stagger('300ms', [
          animate('1s ease-in',
            keyframes([
              style({ opacity: 0, transform: 'translate(-75px)', offset: 0}),
              style({ opacity: .5, transform: 'translate(35)', offset: 0.3}),
              style({ opacity: 1, transform: 'translate(0)', offset: 0.6}),
              style({ opacity: 0.5, transform: 'translate(35)', offset: 0.9}),
              style({ opacity: 1, transform: 'translate(+75)', offset: 1}),
            ])
          )
        ]), {optional: true }),
      ])
    ])
  ]
})
export class CarouselComponent implements OnInit {

  ngOnInit(){
    this.currentItem = this.items[0];
  }

  private currentIndex = 0;
  progress = 0;
  currentItem;
  @Input() items;
  @Input() showControls = true;

  /**
   * Progresses the carousel forward by 1 slide.
   */
 next(){
    this.currentIndex = ++this.currentIndex % this.items.length;
    this.currentItem=this.items[this.currentIndex];
    this.progress=this.currentIndex/(this.items.length-1)*100;
  }

  /**
   * Regresses the carousel backwards by 1 slide.
   */
  prev(){
     this.currentIndex = --this.currentIndex+this.items.length % this.items.length;
     if(this.currentIndex<0){
       this.currentIndex = this.items.length-1
     }
     this.currentItem=this.items[this.currentIndex];
     this.progress=this.currentIndex/(this.items.length-1)*100;
  }

}

import {Component, OnInit} from '@angular/core';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {AuthService} from '../auth.service';
import {CognitoUser} from '@aws-amplify/auth';
import {Router, NavigationEnd, Event} from '@angular/router';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  signinForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required, Validators.min(6)])
  });

  hide = true;
  errorMessage = '';

  get emailInput() {
    return this.signinForm.get('email');
  }

  get passwordInput() {
    return this.signinForm.get('password');
  }

  constructor(
    public _authService: AuthService,
    private _router: Router,
    private _toastr: ToastrService) {
  }

  ngOnInit() {
    this._router.events.subscribe(
      (event: Event) => {
        if (event instanceof NavigationEnd) {
          if (this._router.getCurrentNavigation().extras.state && this._router.getCurrentNavigation().extras.state.notifMessage) {
            this._toastr.warning(this._router.getCurrentNavigation().extras.state.notifMessage, 'Attention !');
          }
        }
      });
  }

  getEmailInputError() {
    if (this.emailInput.hasError('required')) {
      return 'Un username est nécessaire.';
    }
  }

  getPasswordInputError() {
    if (this.passwordInput.hasError('required')) {
      return 'Un mot de passe est nécessaire.';
    }
  }

  signIn() {
    this._authService.signIn(this.emailInput.value, this.passwordInput.value)
      .then((user: CognitoUser | any) => {
        if (user.challengeName == 'NEW_PASSWORD_REQUIRED') {
          this._router.navigate(['/profile'], {
            state: {
              notifMessage: 'Vous devez changer votre mot de passe pour pouvoir vous connecter!',
              notifType: 'WARN'
            }
          });
        }else {
          this._router.navigate(['']);
        }
      })
      .catch((error: any) => {
        this.errorMessage = error.message;
        switch (error.code) {
          case "UserNotConfirmedException":
            this.errorMessage = 'Vous devez valider votre adresse email avant de pouvoir vous connecter';
            break;
          case "NotAuthorizedException":
            this.errorMessage = 'Mauvais pseudo / mail ou mot de passe ou votre compte a été désactivé';
            break;
        }
      })
  }

  async signInWithGoogle() {
    const socialResult = await this._authService.socialSignIn(AuthService.GOOGLE);
    console.log('google Result:', socialResult);
  }

}

import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { HttpClient, HttpEventType, HttpRequest } from '@angular/common/http';

@Component({
  selector: 'app-covixml',
  templateUrl: './covixml.component.html',
  styleUrls: ['./covixml.component.scss']
})
export class CovixmlComponent implements OnInit {

  @ViewChild('file', { read: ElementRef, static: false })
  file: ElementRef;
  downloadUrl: string;
  errorMessage: string;
  loading: boolean;
  panelOpenState = false;
  rgpdAccepted = false;

  constructor(private http: HttpClient) { }

  ngOnInit() {
  }

  reset() {
    this.downloadUrl=null;
    this.errorMessage=null;
  }

  upload() {

      this.downloadUrl=null;
      this.errorMessage=null;
      let files = (this.file.nativeElement as HTMLInputElement).files;

      let sendable = new FormData();
      if(files.length > 0){
        let file: File = files[0];
        sendable.append("file", file);

        const request = new HttpRequest('POST',
          'https://bruno-trinta.fr/covixml/uploadFile',
          sendable,
          {
            reportProgress: true
          });

        this.http.request(request)
          .subscribe((event: any) => {
            if (event.type === HttpEventType.UploadProgress) {
              this.loading = true;
            }
            if (event.type === HttpEventType.Response) {
              const a = document.createElement('a');
              this.downloadUrl=event.body.fileDownloadUri;
              const blob = new Blob([event.body.fileContent], { type: 'application/xml'}),
              url = window.URL.createObjectURL(blob);
              if (navigator.appVersion.toString().indexOf('.NET') > 0 && window.navigator.msSaveBlob) {
                let val = window.navigator.msSaveBlob(blob,  event.body.fileName);
              }
              else {
                a.href = url;
                a.download = event.body.fileName;
                a.click();
                window.URL.revokeObjectURL(url);
              }
              this.loading=false;
            }
          }, error => {
            this.loading=false;
            this.errorMessage="Erreur serveur";
          });
    }
    }

}

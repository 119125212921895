import { Component, Inject } from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ApiService} from '../cv-section/api-services/api.service';

@Component({
  selector: 'mail-interface-dialog',
  templateUrl: 'mail-interface-dialog.html',
  styleUrls: ['../contact-me/contact-me.scss']
})
export class MailInterfaceDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<MailInterfaceDialogComponent>,
    private apiService: ApiService) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSendClick(message): void{
    this.apiService.postMail(message.value);
    this.dialogRef.close();
  }
}

import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MailInterfaceDialogComponent} from './mail-interface-dialog/mail-interface-dialog.component';
import {AuthService} from './auth/auth.service';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'cv-bruno';
  object: string;
  message: string;

  constructor(public dialog: MatDialog, public authService: AuthService, private toastr: ToastrService, private _router: Router) {
  }

  ngOnInit() {
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(MailInterfaceDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      this.message = result;
    });
  }

}

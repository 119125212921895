import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CvSectionComponent} from './cv-section/cv-section.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatButtonModule, MatButtonToggleModule, MatCardModule, MatDialogModule, MatIconModule} from '@angular/material'
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatChipsModule} from '@angular/material/chips';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {CustomInterceptor} from './cv-section/api-services/api.service';
import {MailInterfaceDialogComponent} from './mail-interface-dialog/mail-interface-dialog.component';
import {HomeComponent} from './home/home.component';
import {MatGridListModule} from '@angular/material/grid-list';
import {CovixmlComponent} from './covixml/covixml.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {CarouselComponent} from './carousel/carousel.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {AmplifyAngularModule, AmplifyService} from 'aws-amplify-angular';
import {SignInComponent} from './auth/sign-in/sign-in.component';
import {SignUpComponent} from './auth/sign-up/sign-up.component';
import {ToastrModule} from 'ngx-toastr';
import {ProfileComponent} from "./auth/profile/profile.component";
import {CryptoComponent} from "./crypto/pages/home/crypto.component";
import {WalletComponent} from "./crypto/pages/wallet/wallet.component";
import {MatTableModule} from "@angular/material/table";
import {MatSortModule} from "@angular/material/sort";

@NgModule({
  declarations: [
    AppComponent,
    CvSectionComponent,
    MailInterfaceDialogComponent,
    HomeComponent,
    CovixmlComponent,
    CarouselComponent,
    SignInComponent,
    SignUpComponent,
    ProfileComponent,
    WalletComponent,
    CryptoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatButtonToggleModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    ReactiveFormsModule,
    MatChipsModule,
    HttpClientModule,
    MatGridListModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatProgressBarModule,
    AmplifyAngularModule,
    ToastrModule.forRoot(),
    MatTableModule,
    MatSortModule
  ],
  providers: [{
                               provide: HTTP_INTERCEPTORS,
                               useClass: CustomInterceptor,
                               multi: true,
                             },
                              AmplifyService],
  entryComponents: [MailInterfaceDialogComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }

import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CvSectionComponent} from './cv-section/cv-section.component';
import {HomeComponent} from './home/home.component';
import {CovixmlComponent} from './covixml/covixml.component';
import {SignInComponent} from './auth/sign-in/sign-in.component';
import {SignUpComponent} from './auth/sign-up/sign-up.component';
import {AuthGuard} from './auth/auth.guard';
import {ProfileComponent} from "./auth/profile/profile.component";
import {CryptoComponent} from "./crypto/pages/home/crypto.component";

const routes: Routes = [{path: '', component: HomeComponent},
  {path: 'cv', component: CvSectionComponent},
  {path: 'covixml', component: CovixmlComponent, canActivate: [AuthGuard]},
  {path: 'crypto', component: CryptoComponent, canActivate: [AuthGuard]},
  {path: 'sign-in', component: SignInComponent},
  {path: 'sign-up', component: SignUpComponent},
  {path: 'profile', component: ProfileComponent}];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

export class WalletUtils {

  constructor(){}

  static roundDecimal(number: number, precision: number) {
    var precision = precision || 2;
    var tmp = Math.pow(10, precision);
    return Math.round( number*tmp )/tmp;
  }
}

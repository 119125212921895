import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CryptoApiService {

  private server = 'https://v1qnsrut9d.execute-api.eu-west-1.amazonaws.com/';
  private serverBtc = 'https://ticker.cointelegraph.com/rates';
  //https://ticker.cointelegraph.com/tickers
  //https://blockchain.info/ticker
  private apiUrl = 'production/';
  private gatewayUrl: string;
  private idtoken: string;

  constructor(private http: HttpClient) {

    this.gatewayUrl = this.server + this.apiUrl;
  }

  public getMe(): any {
    const apiPath = this.gatewayUrl + 'me';
    return this.http.get<any>(apiPath, {
      headers: {"Authorization": this.idtoken},
      observe: 'response'
    });
  }

  public getMyWallet(): any {
    const apiPath = this.gatewayUrl + 'mywallet';
    return this.http.get<any>(apiPath, {
      headers: {"Authorization": this.idtoken},
      observe: 'response'
    });
  }

  public getMyTransactions(): any {
    const apiPath = this.gatewayUrl + 'mytransactions';
    return this.http.get<any>(apiPath, {
      headers: {"Authorization": this.idtoken},
      observe: 'response'
    });
  }

  public setCurrentIdToken(idToken: string){
    this.idtoken = idToken;
  }

  public getCurrentIdToken(): string{
    return this.idtoken;
  }

  public getActualBitcoinValue(): any{
    const apiPath = this.serverBtc;
    return this.http.get<any>(apiPath, {
      observe: 'response'
    });
  }

}
